.auth-page {
  justify-content: center;
}

.auth-page__logo {
  margin-bottom: 5vh;
  font-size: 50px;
  font-weight: 200;
  line-height: 50px;
  color: var(--main-color, blue);

  & strong {
    font-weight: 500;
  }
}
