.loader {
  margin: 20vh auto 0;
}

.auth-panel {
  padding: 32px 20px;
  display: flex;
  flex-direction: column;
  width: 343px;
  background-color: var(--neutral-color, white);
  border: 1px solid var(--other_dark_grey_02);
  border-radius: 16px;
  box-shadow: 0 4px 20px var(--other_black_01);
  justify-content: flex-start;
  align-items: center;

  @mixin not_mobile {
    padding: 32px;
    width: 460px;
  }
}

.panel-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: var(--other_dark_grey);
  align-items: center;
  gap: 4px;

  @mixin not_mobile {
    flex-direction: row;
    gap: 8px;
  }
}

.panel-message .link {
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: var(--textator_primary_hover);
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
}
