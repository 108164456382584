.form {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: left;
}

.form-title {
  margin: 0 0 24px;
  width: 100%;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: var(--other_dark_grey);
  @mixin not_mobile {
    margin-bottom: 32px;
    font-size: 36px;
    line-height: 54px;
    text-align: left;
  }
}

.children-container {
  margin-bottom: 11px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  @mixin not_mobile {
    gap: 20px;
  }
}

.forgot-button {
  margin-bottom: 16px;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  color: var(--textator_primary_hover);
  background-color: transparent;
  border: 0;
  align-self: flex-end;

  &:focus {
    outline: none;
  }

  @mixin not_mobile {
    margin-bottom: 20px;
  }
}

.submit-button {
  margin-bottom: 24px;
  width: 100%;
  @mixin not_mobile {
    margin-bottom: 32px;
  }
}
