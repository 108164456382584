:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;

  --cell-size: 8vw;

  --border-radius: 10px;
  --small-border-radius: 4px;
}

html, body, #root {
  height: 100%;
}

